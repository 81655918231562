export default class Language {
  code: string
  name: string
  rtl: boolean

  constructor(data: any) {
    this.code = data.code
    this.name = data.name
    this.rtl = data.rtl || false
  }
}

export const LANGUAGES = [
  new Language({ code: 'en', name: 'English', rtl: false }),
  new Language({ code: 'fi', name: 'Finnish', rtl: false }),
  new Language({ code: 'sk', name: 'Slovak', rtl: false }),
  new Language({ code: 'ar', name: 'Arabic', rtl: true })
]
