import React from 'react'
import { FormGroup, InputGroup, TextArea, Label, Tabs, Tab } from '@blueprintjs/core'
import { Column } from '../flex'
import Feature from '../../models/feature'
import Language from '../../models/language'
import deepEqual from 'deep-equal'


interface Props {
  feature: Feature
  field: string
  fieldIndex?: number
  fieldName: string | boolean
  languages: Array<Language>
  area: boolean
  onChange: (feature: Feature) => void
}

interface State {
  tab: string
}

export default class Translator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    console.log('props', props)
    this.state = {
      tab: `translator-${this.props.field}-en`
    }
  }

  onChange(e: any, language: Language) {
    const value = e.target.value
    const feature = new Feature(this.props.feature.json)
    // const field = this.getFieldName(language)

    if (!feature.properties[this.props.field]) {
      feature.properties[this.props.field] = {}
    }

    if (typeof this.props.fieldIndex !== 'undefined') {
      if (typeof feature.properties[this.props.field][this.props.fieldIndex] === 'undefined') {
        feature.properties[this.props.field][this.props.fieldIndex] = {}
      }

      feature.properties[this.props.field][this.props.fieldIndex][language.code] = value
    } else {
      if (typeof feature.properties[this.props.field] !== 'object') {
        feature.properties[this.props.field] = {}
      }

      feature.properties[this.props.field][language.code] = value
    }

    console.log('translator onChange', feature)
    this.props.onChange(feature)
  }

  renderArea(language: Language) {
    const w = (window as any)
    w.pica = this
    // const field = this.getFieldName(language).toString()
    const fieldValue = this.props.feature.properties[this.props.field] || {}
    let value
    if (typeof this.props.fieldIndex !== 'undefined') {
      const indexValue = fieldValue[this.props.fieldIndex] || {}
      value = indexValue[language.code] || ''
    } else {
      value = fieldValue
    }

    return <TextArea
      value={value}
      onChange={(e:any) => {
        this.onChange(e, language)
      }}
    />
  }

  getDefaultValue() {
    const value = {} as any
    this.props.languages.forEach(language => {
      if (typeof this.props.fieldIndex !== 'undefined') {
        value[this.props.fieldIndex][language.code] = ''
      } else {
        value[language.code] = ''
      }
    })
    return value
  }

  renderTextField(language: Language) {
    const fieldValue = this.props.feature.properties[this.props.field] || this.getDefaultValue()

    let value
    if (typeof this.props.fieldIndex !== 'undefined') {
      const indexValue = fieldValue[this.props.fieldIndex] || {}
      value = indexValue[language.code] || ''
    } else {
      value = fieldValue[language.code] || ''
    }
    return <InputGroup
      style={{ direction: language.rtl ? 'rtl' : 'ltr' }}
      value={value}
      onChange={(e:any) => {
        this.onChange(e, language)
      }}
    />
  }

  renderTranslation(language: Language) {
    return <Tab
      key={`translator-${this.props.field}-${language.code}`}
      id={`translator-${this.props.field}-${language.code}`}
      title={language.code}
      panel={<Column flex={1}>
        <FormGroup>
          { this.props.area ? this.renderArea(language) : this.renderTextField(language) }
        </FormGroup>
      </Column>}
    />
  }

  render() {
    return <Column flex={1} className="edit-block translator">
      { this.props.fieldName && <Label style={{marginBottom: 0}}>{ this.props.fieldName }</Label> }
      <Tabs selectedTabId={this.state.tab} onChange={(tab: string) => {
        if (this.state.tab !== tab) {
          console.log('changing tab from', this.state.tab, 'to', tab)
          this.setState({ tab })
        }
      }}>
        { this.props.languages.map(language => this.renderTranslation(language)) }
      </Tabs>
    </Column>
  }
}