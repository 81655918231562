import React, { Component } from 'react'
import { Collapse, FormGroup, MenuItem } from '@blueprintjs/core'
import { Controlled as CodeMirror } from 'react-codemirror2'
require('codemirror/mode/xml/xml')
require('codemirror/mode/javascript/javascript')

interface Props {
  id: string
  value: any
  onChange: (value: any) => void
  label: string
}

interface State {
  value: any
  isOpen: boolean
}

export default class JSONEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false,
      value: this.parseValue(props.value)
    }
  }

  parseValue(value: any) {
    return typeof this.props.value === 'object' ? JSON.stringify(this.props.value) : this.props.value
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.value !==  this.props.value && this.state.value !== prevState.value) {
      this.setState({
        value: this.parseValue(this.props.value)
      })
    }
  }

  render() {
    const label = <MenuItem
      text={this.props.label}
      icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'}
      onClick={() => this.setState({ isOpen: !this.state.isOpen })}
    />
    return <div className="edit-block menu-block">
      <FormGroup label={label}>
        <Collapse isOpen={this.state.isOpen}>
          <CodeMirror
            value={this.parseValue(this.props.value)}
            options={{
              mode: 'json',
              theme: 'github',
              lineNumbers: true
            }}
            onBeforeChange={async (editor, data, value) => {
              this.setState({ value }, () => {
                this.props.onChange(value)
              })
            }}
          />
        </Collapse>
      </FormGroup>
    </div>
  }
}
