import React, { Component } from 'react'
import { Alignment, Dialog, Classes, Colors, Icon, Button, MenuItem, InputGroup, Navbar, NavbarDivider, NavbarGroup, NavbarHeading, Popover } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import uuid from 'uuid'
import produce from 'immer'
import { orderBy } from 'lodash'
import { Column, Row } from '../flex'
import AmenityPopover from './amenities/amenity_popover'
import Amenity from '../../models/amenity'
import AmenityCategory from '../../models/amenity_category'
import { NodeState } from '../../types/node_state'
import amenityStore from '../../amenity_store'
import repository from '../../repository'
import notifier from '../../notifier'

type AmenityMap = { [id: string]: Amenity[] }
type CategoryMap = { [id: string]: AmenityCategory }

const CategorySelect = Select.ofType<AmenityCategory>()

interface Props {
  isOpen: boolean
  onClose: () => void
}

interface State {
  tab: string,
  mapping?: string
  amenities: Amenity[]
  amenityCategories: AmenityCategory[]
  category: AmenityCategory
  categories: CategoryMap
  nodes: AmenityMap
  nodeState: NodeState
  selected?: Amenity
  selectedCategory?: AmenityCategory
  newAmenityOpen: boolean
  newAmenityCategoryOpen: boolean
  amenityTitle: string
  categoryTitle: string
  amenityIcon: string
  deleteCategoryOpen: boolean
}

export default class Amenities extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tab: 'categories',
      mapping: 'poi',
      amenities: [],
      amenityCategories: [],
      category: new AmenityCategory({}),
      categories: {},
      nodes: {},
      nodeState: {},
      newAmenityOpen: false,
      newAmenityCategoryOpen: false,
      amenityTitle: '',
      categoryTitle: '',
      amenityIcon: '',
      deleteCategoryOpen: false
    }

    this.onOpened = this.onOpened.bind(this)
    this.update = this.update.bind(this)
    this.mapNodes = this.mapNodes.bind(this)
    this.mapCategories = this.mapCategories.bind(this)
    this.storeObserver = this.storeObserver.bind(this)
    this.save = this.save.bind(this)
    this.addCategory = this.addCategory.bind(this)
    this.renderAmenities = this.renderAmenities.bind(this)
    this.createAmenity = this.createAmenity.bind(this)
    this.updateAmenity = this.updateAmenity.bind(this)
    this.deleteAmenity = this.deleteAmenity.bind(this)
  }

  componentDidMount() {
    amenityStore.observe(this.storeObserver)
  }

  componentWillUnmount() {
    amenityStore.cancel(this.storeObserver)
  }

  storeObserver() {
    this.update()
  }

  async update() {
    await this.setState(produce(this.state, state => {
      state.amenities = amenityStore.amenities
      state.amenityCategories = amenityStore.categories
      state.nodeState = {}
      state.amenities.forEach(amenity => state.nodeState[amenity.id] = false)
      if (!state.category.exists && state.amenityCategories.length > 0) {
        state.category = state.amenityCategories[0]
      }
    }))
    await this.setState(produce(this.state, state => {
      state.categories = this.mapCategories()
      state.nodes = this.mapNodes()
    }))
  }

  mapCategories() {
    const categoryMap = {} as CategoryMap
    orderBy(this.state.amenityCategories, ['title', 'asc'])
      .forEach(amenityCategory => categoryMap[amenityCategory.id] = amenityCategory)

    return categoryMap
  }

  mapNodes() {
    const amenityMap = {} as AmenityMap

    this.state.amenities.forEach(amenity => {
      const category = amenityMap[amenity.category || 'general'] || []
      category.push(amenity)
      amenityMap[amenity.category] = category
    })

    return amenityMap
  }

  async onOpened() {
    await this.update()
  }

  async save() {
    if (this.state.selected) {
      await amenityStore.save(this.state.selected)
    }
  }

  addCategory() {
    this.setState(produce(this.state, state => {
      state.amenityCategories.push(new AmenityCategory({ title: 'New Category'}))
    }))
  }

  async createAmenity(amenity: Amenity) {
    amenity.category = this.state.category.id
    try {
      const response = await repository.createAmenity(amenity)
      amenityStore.add(new Amenity(response))
      await this.setState({ newAmenityOpen: false, amenityTitle: '', amenityIcon: '' })
      await this.update()
      notifier.success('Amenity was successfuly created')
    } catch (e) {
      notifier.failure('Unable to create amenity')
    }
  }

  async updateAmenity(amenity: Amenity) {
    amenity.category = this.state.category.id
    try {
      const response = await repository.updateAmenity(amenity)
      amenityStore.update(response)
      this.toggleAmenityState(amenity)
      await this.setState({ newAmenityOpen: false, amenityTitle: '', amenityIcon: '' })
      await this.update()
      notifier.success('Amenity was successfuly updated')
    } catch (e) {
      notifier.failure('Unable to update amenity')
    }
  }

  async createCategory() {
    const category = new AmenityCategory({
      id: uuid(),
      title: this.state.categoryTitle
    })
    try {
      const response = await repository.createAmenityCategory(category)
      amenityStore.addCategory(response)
      await this.setState({ newAmenityCategoryOpen: false, categoryTitle: '', category: response })
      await this.update()
      notifier.success('Category was successfuly created')
    } catch (e) {
      notifier.failure('Unable to create category')
    }
  }

  async deleteAmenity(amenity: Amenity) {
    try {
      await repository.deleteAmenity(amenity)
      await amenityStore.delete(amenity)
      const nodeState = this.state.nodeState
      nodeState[amenity.id] = false
      await this.setState({ nodeState })
      notifier.success('Amenity was successfuly removed')
    } catch (e) {
      notifier.failure('Unable to delete amenity')
    }
  }

  async deleteCategory(category: AmenityCategory) {
    try {
      await repository.deleteAmenityCategory(category)
      await amenityStore.deleteCategory(category)
      await this.update()
      const def = this.state.amenityCategories.find(c => c.title === 'Default')
      if (def) {
        await this.setState({
          category: def,
          deleteCategoryOpen: false
        })
      }
      notifier.success('Category was successfuly removed')
    } catch (e) {
      notifier.failure('Unable to delete category')
    }
  }

  toggleAmenityState(amenity: Amenity) {
    const nodeState = this.state.nodeState
    nodeState[amenity.id] = !nodeState[amenity.id]
    this.setState({ nodeState })
  }

  renderAmenity(amenity: Amenity) {
    return <div
      className="amenity-editor-amenity"
      onClick={() => this.toggleAmenityState(amenity)}>
      <div className="amenity-editor-image">
        { amenity.exists && <img alt={amenity.title} src={amenity.icon}/> }
        { !amenity.exists && <Icon color="#fff" iconSize={72} icon="plus" style={{ marginTop: 10, marginBottom: 4, backgroundColor: '#08c'}} /> }
      </div>
      <div className="amenity-editor-title">{ amenity.title }</div>
    </div>
  }

  renderAmenities() {
    if (!this.state.category.exists) {
      return <h4 style={{ color: Colors.GRAY3, marginTop: 10 }}>Select category</h4>
    }

    const amenities = [...(this.state.nodes[this.state.category.id] || [])]
    if (!amenities) {
      return null
    }

    const grid = []
    const perRow = 4
    const rows = Math.floor(amenities.length / perRow) + 1
    for (let row = 0; row < rows; row++) {
      grid.push(amenities.slice(row * perRow, row * perRow + perRow))
    }
    return <div>
      <div className="amenities-icons-browser">
        { grid.map((row, rowIndex) => <Row key={`amenities-row-${rowIndex}`} flex={1}>
          { row.map((amenity, colIndex) => <Column key={`amenities-row-${rowIndex}-${colIndex}`} flex={0} style={{ alignContent: 'flex-start' }}>
            <AmenityPopover
              isOpen={this.state.nodeState[amenity.id]}
              amenity={amenity}
              onSave={this.updateAmenity}
              onClose={() => this.toggleAmenityState(amenity)}
              onDelete={this.deleteAmenity}>
              { this.renderAmenity(amenity)}
            </AmenityPopover>
          </Column>)}
        </Row>) }
      </div>
    </div>
  }

  render() {
    return <Dialog
      className="amenity-editor"
      isOpen={this.props.isOpen}
      onClose={this.props.onClose}
      onOpened={this.onOpened}
      style={{ width: 857, height: 670 }}
      title="Amenity Manager">
      <div className={Classes.DIALOG_BODY}>
        <Navbar>
          <NavbarGroup align={Alignment.LEFT}>
            <NavbarHeading>Category</NavbarHeading>
            <NavbarDivider />
            <CategorySelect
              filterable={false}
              onItemSelect={category => this.setState({ category })}
              items={this.state.amenityCategories}
              itemRenderer={(item, options) =>
                <MenuItem
                  key={`category-select-${item.id}`}
                  text={item.title}
                  onClick={options.handleClick}
                />
              }>
              <Button
                className={Classes.MINIMAL}
                text={this.state.category.exists ? this.state.category.title : 'Select Category'}
                style={{ marginRight: 20 }}
              />
            </CategorySelect>
            <Popover isOpen={this.state.deleteCategoryOpen}>
              <Button
                icon="trash"
                minimal
                disabled={this.state.category.title === 'Default'}
                onClick={() => this.setState({ deleteCategoryOpen: true })}
              />
              <Button text="Confirm delete" intent="danger" icon="info-sign" onClick={() => this.deleteCategory(this.state.category)}/>
            </Popover>
          </NavbarGroup>
          <NavbarGroup align={Alignment.RIGHT}>
            <Popover isOpen={this.state.newAmenityCategoryOpen}>
              <Button icon="plus" text="Add Category" style={{ marginRight: 20 }} onClick={() => this.setState({ newAmenityCategoryOpen: true })} />
              <Row flex={1}>
                <InputGroup
                  fill
                  style={{ width: '100%' }}
                  placeholder="Enter category title"
                  value={this.state.categoryTitle}
                  onChange={(e: any) => this.setState({
                    categoryTitle: e.currentTarget.value
                  })}
                />
                <Button icon="tick" intent="success" onClick={() => this.createCategory() }/>
                <Button icon="cross" intent="danger" minimal  onClick={() => this.setState({ newAmenityCategoryOpen: false })} />
              </Row>
            </Popover>
            <AmenityPopover
              isOpen={this.state.newAmenityOpen}
              amenity={new Amenity({})}
              onSave={this.createAmenity}
              onClose={() => this.setState({ newAmenityOpen: false })}
              onDelete={() => {}}>
              <Button icon="plus" text="Add Amenity" intent="primary" onClick={() =>
                this.setState({ newAmenityOpen: true })}
              />
            </AmenityPopover>
          </NavbarGroup>
        </Navbar>
        <Row flex={1}>
          <Column flex={3} className="amenity-editor-icons">
            { !this.state.selected && this.renderAmenities() }
          </Column>
        </Row>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon="cross"
            text="Close"
            onClick={this.props.onClose}
          />
        </div>
      </div>
    </Dialog>
  }
}
