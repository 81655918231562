const CommonSelectors = require('@mapbox/mapbox-gl-draw/src/lib/common_selectors')
const Constants = require('@mapbox/mapbox-gl-draw/src/constants')

const DrawText = {}

DrawText.onSetup = function() {
  const point = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      type: 'text'
    },
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: []
    }
  });

  this.addFeature(point);

  this.clearSelectedFeatures();
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  // this.activateUIButton(Constants.types.POINT);

  this.setActionableState({
    trash: true
  });

  return { point };
};

DrawText.stopDrawingAndRemove = function(state) {
  this.deleteFeature([state.point.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

DrawText.onTap = DrawText.onClick = function(state, e) {
  this.updateUIClasses({ mouse: Constants.cursors.MOVE })
  state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat)
  const point = state.point.toGeoJSON()
  point.properties.type = 'text'
  this.map.fire(Constants.events.CREATE, {
    features: [point]
  })
  this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.point.id] })
};

DrawText.onStop = function(state) {
  this.activateUIButton();
  if (!state.point.getCoordinate().length) {
    this.deleteFeature([state.point.id], { silent: true });
  }
};

DrawText.toDisplayFeatures = function(state, geojson, display) {
  // Never render the point we're drawing
  const isActivePoint = geojson.properties.id === state.point.id;
  geojson.properties.active = (isActivePoint) ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
  console.log('toDisplayFeatures', state, geojson, display)
  if (!isActivePoint) return display(geojson);
};

DrawText.onTrash = DrawText.stopDrawingAndRemove;

DrawText.onKeyUp = function(state, e) {
  if (CommonSelectors.isEscapeKey(e) || CommonSelectors.isEnterKey(e)) {
    return this.stopDrawingAndRemove(state, e);
  }
};

module.exports = DrawText;
