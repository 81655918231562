import React from 'react'
import { Button, Checkbox, FormGroup, InputGroup, NumericInput, MenuItem, Switch } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { Column } from '../../flex'
import Translator from '../translator'
import AmenityPicker from '../amenity_picker'
import FloorSelection from '../../map/floor_selection'
import PlaceSelection from '../../map/place_selection'
import Amenity from '../../../models/amenity'
import Feature from '../../../models/feature'
import { LANGUAGES } from '../../../models/language'
import Place from '../../../models/place'
import Floor from '../../../models/floor'
import AmenityCategory from '../../../models/amenity_category'
import MetadataEditor from '../metadata_editor'
import JSONEditor from '../json_editor'
import Media from '../media'
import PoiType, { POI_TYPE } from '../../../models/poi_type'
import POI_TYPES from '../../../poi_types'
import { defaultIcon } from '../../../icons'
import { NodeState } from '../../../types/node_state'
import MapContext, { MapContextInterface } from '../../../map_context'
import { debug } from '../../../common'

// import { Priority, Priorities } from '../../../types/priority'
const PoiTypeSelect = Select.ofType<PoiType>()
// const PrioritySelect = Select.ofType<Priority>()

interface Props {
  amenities: Array<Amenity>
  amenityCategories: Array<AmenityCategory>
  point: Feature
  places: Array<Place>
  floors: Array<Floor>
  onChange: (feature: Feature) => void
}

interface State {
  amenity: Amenity
  nodeState: NodeState
}

export default class EditPoint extends React.Component<Props, State> {
  static contextType = MapContext

  constructor(props: Props) {
    super(props)
    this.state = {
      amenity: this.getAmenity(),
      nodeState: {}
    }
    this.onGroupChange = this.onGroupChange.bind(this)
    this.onRangeChange = this.onRangeChange.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.point.properties.amenity !== this.state.amenity.id) {
      console.log('setting amenity', this.props.point.properties.amenity, this.state.amenity.id, this.getAmenity())
      this.setState({ amenity: this.getAmenity( )})
    }
  }

  getFloor(feature: Feature) {
    const floor = this.props.floors.find(p => p.id === feature.properties.floor_id)
    if (!floor) {
      return new Floor({})
    }
    return floor
  }

  getPlace(feature: Feature) {
    const place = this.props.places.find(p => p.id === feature.properties.place_id)
    if (!place) {
      return new Place({})
    }
    return place
  }

  getAmenity() {
    const amenity = this.props.amenities.find(amenity => amenity.id === this.props.point.properties.amenity)
    return amenity || new Amenity({})
  }

  getIcon() {
    const poiType = this.getType()
    if (!poiType) {
      return
    }

    if (poiType.type === POI_TYPE.POI && this.state.amenity.exists) {
      return <img alt={this.state.amenity.title} className="editor-amenity" src={this.state.amenity.icon} />
    } else {
      return <img alt={poiType.title} className="editor-amenity" src={poiType.icon} />
    }
  }

  getType() {
    let type = POI_TYPES.find(poiType => poiType.type === this.props.point.properties.type)
    if (!type) {
      type = POI_TYPES.find(poiType => poiType.type === POI_TYPE.POI)
    }

    return type
  }

  poiTypeRenderer(poiType: PoiType, options: any) {
    return <MenuItem
      key={poiType.type}
      text={poiType.title}
      icon={<img style={{ width: 22 }} alt={poiType.title} src={poiType.icon} />}
      onClick={options.handleClick}
    />
  }

  // priorityRenderer(priority: Priority, options: any) {
  //   return <MenuItem
  //     key={`priority-${priority.id}`}
  //     text={priority.title}
  //     onClick={options.handleClick}
  //   />
  // }

  onGroupChange(e: any) {
    const feature = new Feature(this.props.point.json)
    feature.properties.group = e.currentTarget.value
    this.props.onChange(feature)
  }

  onRangeChange(valueAsNumber: number, valueAsString: string) {
    const feature = new Feature(this.props.point.json)
    feature.properties.range = valueAsNumber
    this.props.onChange(feature)
  }

  render() {
    let poiType = POI_TYPES.find(poiType => poiType.type === this.props.point.properties.type)
    if (!poiType) {
      poiType = new PoiType("poi", "Default", defaultIcon)
    }

    return <Column flex={1}>
      <h6 className="bp3-heading edit-heading">
        <div style={{width: 44, float: 'left'}}>
          { this.getIcon() }
        </div>
        <Column flex={1}>
          <div style={{ fontSize: 12, fontWeight: 'bold'}}>{ poiType && poiType.title }</div>
          <div style={{ fontSize: 16 }}>{ this.props.point.properties.title }</div>
          <div style={{ fontSize: 10 }}>
            lat { this.props.point.geometry.coordinates[1].toFixed(8) }
            &nbsp;
            lng { this.props.point.geometry.coordinates[0].toFixed(8) }
            &nbsp;
            level { this.props.point.properties.level }
          </div>
          <div style={{ fontSize: 10 }}></div>
        </Column>
      </h6>

      <Column flex={1} className="edit-block">
        <PlaceSelection
          simple={false}
          selected={this.getPlace(this.props.point)}
          onSelect={(place) => {
            const point = new Feature(this.props.point.json)
            point.properties.place_id = place.id
            this.props.onChange(point)
          }}
        />
      </Column>

      <Column flex={1} className="edit-block">
        { this.props.point.isLevelChanger && <FormGroup
          label="Floors"
          inline={true}>
            { this.props.floors.map(floor =>
              <Checkbox
                key={`floor-checkbox-${floor.id}`}
                checked={this.props.point.properties.levels ? this.props.point.properties.levels.includes(floor.level) : false}
                onChange={(evt) => {
                  const feature = new Feature(this.props.point.json)
                  if (typeof feature.properties.levels === 'undefined') {
                    feature.properties.levels = []
                  }
                  let included = feature.properties.levels.includes(floor.level)

                  if (evt.currentTarget.checked && !included) {
                    feature.properties.levels.push(floor.level)
                  } else {
                    feature.properties.levels = feature.properties.levels.filter((l: number) => l !== floor.level)
                  }

                  feature.properties[`__level_${floor.level}`] = feature.properties.levels.includes(floor.level)
                  this.props.onChange(feature)
                }}>
              { floor.name }
              </Checkbox>
            )}
        </FormGroup> }
        { !this.props.point.isLevelChanger &&
          <FloorSelection
            simple={false}
            selected={this.getFloor(this.props.point)}
            onSelect={(floor) => {
              const point = new Feature(this.props.point.json)
              point.properties.floor_id = floor.id
              point.properties.level = floor.level
              this.props.onChange(point)
            }}
          />
        }
      </Column>

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Type"
          inline={true}>
          <PoiTypeSelect
            items={POI_TYPES}
            itemRenderer={this.poiTypeRenderer}
            activeItem={poiType}
            onItemSelect={(poiType) => {
              const point = new Feature(this.props.point.json)
              const level = (this.context as MapContextInterface).floor.level

              point.properties.type = poiType.type
              if (point.isLevelChanger && typeof point.properties.levels === 'undefined') {
                point.properties.levels = [ level ]
                point.properties[`__level_${level}`] = true
              }

              if (!point.isLevelChanger && Array.isArray(point.properties.levels)) {
                point.properties.level = level
                delete point.properties.levels
              }

              debug.log('on feature type switch', point)

              this.props.onChange(point)
            }}>
            <Button text={poiType ? poiType.title : 'Choose Type'}
                    icon={poiType && <img style={{ width: 22 }} alt={poiType.title} src={poiType.icon} />}
                    minimal={true}
                    rightIcon="double-caret-vertical"/>
          </PoiTypeSelect>
        </FormGroup>
      </Column>

      <AmenityPicker
        feature={this.props.point}
        amenities={this.props.amenities}
        amenityCategories={this.props.amenityCategories}
        onChange={this.props.onChange}
      />

      <Column flex={1} className="edit-block">
        <FormGroup label="Range (m)" inline={true}>
          <NumericInput
            buttonPosition="none"
            value={this.props.point.properties.range}
            onValueChange={this.onRangeChange}/>
        </FormGroup>
      </Column>

      <Translator
        area={false}
        feature={this.props.point}
        field="title_i18n"
        fieldName="Title"
        languages={LANGUAGES}
        onChange={this.props.onChange}
      />

      { this.props.point.isLevelChanger && <Column flex={1} className="edit-block">
        <FormGroup
          label="Group"
          inline={true}>
            <InputGroup
              value={this.props.point.properties.group || ''}
              onChange={this.onGroupChange}
            />
        </FormGroup>
      </Column> }

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Icon Only"
          inline={true}>
            <Switch
              checked={this.props.point.properties.icon_only || false}
              onChange={(e) => {
                const feature = new Feature(this.props.point.json)
                feature.properties.icon_only = e.currentTarget.checked
                if (e.currentTarget.checked && feature.properties.text_only) {
                  feature.properties.text_only = false
                }
                this.props.onChange(feature)
              }}
            />
        </FormGroup>
      </Column>

      <Column flex={1} className="edit-block">
        <FormGroup
          label="Text Only"
          inline={true}>
            <Switch
              checked={this.props.point.properties.text_only || false}
              onChange={(e) => {
                const feature = new Feature(this.props.point.json)
                feature.properties.text_only = e.currentTarget.checked
                if (e.currentTarget.checked && feature.properties.icon_only) {
                  feature.properties.icon_only = false
                }
                this.props.onChange(feature)
              }}
            />
        </FormGroup>
      </Column>

      {/* <Column flex={1} className="edit-block">
        <FormGroup
          label="Priority"
          inline={true}>
          <PrioritySelect
            items={Priorities}
            itemRenderer={this.priorityRenderer}
            activeItem={this.props.point.properties.priority || Priorities[0].id}
            onItemSelect={(priority) => {
              const point = new Feature(this.props.point.json)
              point.properties.priority = priority.id
              this.props.onChange(point)
            }}>
            <Button text={this.props.point.properties.priority ? (Priorities.find(p => p.id === this.props.point.properties.priority) as Priority).title : Priorities[0].title}
                    minimal={true}
                    rightIcon="double-caret-vertical"/>
          </PrioritySelect>
        </FormGroup>
      </Column> */}

      <JSONEditor
        label="General Metadata"
        id={this.props.point.properties.id}
        value={this.props.point.properties.metadata || "{}"}
        onChange={(value) => {
          const feature = new Feature(this.props.point.json)
          feature.properties.metadata = value
          this.props.onChange(feature)
        }}
      />

      <MetadataEditor
        feature={this.props.point}
        languages={LANGUAGES}
        onChange={this.props.onChange}
      />

      <Media feature={this.props.point} onChange={this.props.onChange}/>

      <JSONEditor
        label="Raw GeoJSON"
        id={`${this.props.point.properties.id}-raw`}
        value={JSON.stringify(this.props.point, null, 2)}
        onChange={(value) => {
          try {
            const data = JSON.parse(value)
            const feature = new Feature(data)
            this.props.onChange(feature)
          } catch (e) {
            console.error('Raw JSON Editor Format ERROR', e)
          }
        }}
      />
    </Column>
  }
}