import PathFinder from './PathFinding'
import Feature, { FeatureCollection } from './models/feature'
import { lineString } from '@turf/helpers'
import { debug } from './common'

export default class Routing {
  data: FeatureCollection
  pathFinder: any

  constructor() {
    this.data = new FeatureCollection({})
  }

  setData(collection: FeatureCollection) {
    this.data = collection
    this.pathFinder = new PathFinder(this.data)
    this.pathFinder.preprocess();
    // pathfinding.load(neighbourList, wallOffsets);
    // this.pathFinder.setConfiguration({
        // avoidEscalators: true,
        // avoidNarrowPaths: true,
        // avoidRevolvingDoors: true
    // });
  }

  route(start: Feature, finish: Feature) {
    debug.log('routing with start', start, 'finish', finish)
    const points = this.pathFinder.runAStar(start, finish)
    if (!points) {
      return null
    }

    const levelPoints = {} as any
    points.forEach((point: any) => {
      if (typeof levelPoints[point.properties.level] === 'undefined') {
        levelPoints[point.properties.level] = []
      }
      levelPoints[point.properties.level].push(point)
    })

    const levels = Object.keys(levelPoints)
    const levelPaths = {} as any
    levels.forEach(level => {
      levelPaths[level] = new Feature(lineString(levelPoints[level].map((point: any) => point.geometry.coordinates)))
    })
    return levelPaths
  }
}