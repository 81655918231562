import React, { Component } from 'react'
import { Popup } from 'react-mapbox-gl'
import { Button, ButtonGroup, Icon, Menu, MenuItem, Tooltip } from '@blueprintjs/core'
import uuid from 'uuid'
import Feature from '../../models/feature'
import Floor from '../../models/floor'
import mapboxgl from 'mapbox-gl'
import { LINE_TYPES, POLYGON_TYPES } from '../../common'

interface Props {
  coordinates: mapboxgl.LngLat
  floor: Floor
  selected: Feature[]
  enablePolygons: boolean
  onPointAdd: (feature: Feature) => void
  onRouteStart: (feature: Feature) => void
  onRouteFinish: (feature: Feature) => void
  onSelect: (feature: Feature) => void
  onClose: () => void
}

export default class EmptyPopup extends Component<Props> {
  constructor(props: Props) {
    super(props)
    this.onAddPoint = this.onAddPoint.bind(this)
    this.onRouteStart = this.onRouteStart.bind(this)
    this.onRouteFinish = this.onRouteFinish.bind(this)
  }

  componentDidUpdate() {}

  onAddPoint() {
    const point = Feature.point(uuid.v4(), this.props.coordinates.lat, this.props.coordinates.lng)
    point.properties.type = 'poi'
    point.properties.amenity = 'default'
    this.props.onPointAdd(point)
  }

  onRouteStart() {
    const { lat, lng } = this.props.coordinates
    const routeStart = Feature.point('route-start', lat, lng, {
      usecase: 'routing',
      amenity: 'route_start',
      level: this.props.floor.level,
    })
    this.props.onRouteStart(routeStart)
  }

  onRouteFinish() {
    const { lat, lng } = this.props.coordinates
    const routeFinish = Feature.point('route-finish', lat, lng, {
      usecase: 'routing',
      amenity: 'route_finish',
      level: this.props.floor.level,
    })
    this.props.onRouteFinish(routeFinish)
  }

  hasFeatures() {
    const currentTypes = ['Point', ...LINE_TYPES]
    if (this.props.enablePolygons) {
      currentTypes.push(...POLYGON_TYPES)
    }
    return this.props.selected.filter(f => currentTypes.includes(f.geometry.type)).length > 0
  }

  renderItems() {
    if (this.props.selected.length === 0) {
      return null
    }

    const items = [] as any[]
    const points = this.props.selected.filter(f => f.geometry.type === 'Point')
    const lines = this.props.selected.filter(f => LINE_TYPES.includes(f.geometry.type))
    const polygons = this.props.selected.filter(f => POLYGON_TYPES.includes(f.geometry.type))

    if (points.length > 0) {
      // items.push(<MenuDivider key="item_select-divider-points" className="menu-section-header" title="Points"/>)
      points.forEach((point, index) => {
        items.push(<MenuItem
          key={`item_select-feature-${point.properties.id}`}
          text={point.properties.title || `Point #${index}`}
          label={point.geometry.type}
          icon={<Icon icon="map-marker" color="#c08" />}
          onClick={() => this.props.onSelect(point)}
        />)
      })
    }

    if (lines.length > 0) {
      // items.push(<MenuDivider key="item_select-divider-lines" className="menu-section-header" title="Lines"/>)
      lines.forEach((line, index) => {
        items.push(<MenuItem
          key={`item_select-feature-${line.properties.id}`}
          text={line.properties.title || `Line #${index}`}
          label={line.geometry.type}
          icon={<Icon icon="flow-linear" color="#c08" />}
          onClick={() => this.props.onSelect(line)}
        />)
      })
    }

    if (this.props.enablePolygons) {
      if (polygons.length > 0) {
        // items.push(<MenuDivider key="item_select-divider-polygons" className="menu-section-header" title="Polygons"/>)
      }

      polygons.forEach((polygon, index) => {
        let polygonType = 'Polygon'
        if (polygon.properties.segment) {
          polygonType = 'Segment'
        }

        if (polygon.properties.routable) {
          polygonType = 'Routable'
        }

        items.push(<MenuItem
          key={`item_select-feature-${polygon.properties.id}`}
          text={polygon.properties.title || `${polygonType} #${index}`}
          label={polygonType}
          icon={<Icon icon="polygon-filter" color="#0c8" />}
          onClick={() => this.props.onSelect(polygon)}
        />)
      })
    }

    return items
  }

  render() {
    return <Popup
      className="map-popup toolbar-popup"
      coordinates={[this.props.coordinates.lng, this.props.coordinates.lat]}
      offset={[112, 10]}>
      {/* <ButtonGroup style={{width: '100%'}}>
        <Tooltip content="Add New Point" className="menu-button-tooltip">
          <Button icon="map-marker" small minimal onClick={this.onAddPoint}/>
        </Tooltip>
        <Tooltip content="Set as Route Start" className="menu-button-tooltip">
          <Button icon="double-chevron-right" small minimal onClick={this.onRouteStart}/>
        </Tooltip>
        <Tooltip content="Set as Route Destination" className="menu-button-tooltip">
          <Button icon="double-chevron-left" small minimal onClick={this.onRouteFinish}/>
        </Tooltip>
        <div style={{flex: 1}}/>
        <Tooltip content="Cancel" className="menu-button-tooltip">
          <Button icon="cross" small minimal onClick={this.props.onClose}/>
        </Tooltip>
      </ButtonGroup> */}
      { this.hasFeatures() &&
        <Menu>
          { this.renderItems() }
        </Menu>
      }
    </Popup>
  }
}
