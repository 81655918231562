import PoiType, { POI_TYPE } from './models/poi_type'

import { door, poi, entrance, ticketGate, hazard, decision, landmark, elevator, escalator, staircase } from './icons'

const POI_TYPES = [
  new PoiType(POI_TYPE.POI, 'POI', poi),
  new PoiType(POI_TYPE.STAIRCASE, 'Staircase', staircase),
  new PoiType(POI_TYPE.ELEVATOR, 'Elevator', elevator),
  new PoiType(POI_TYPE.ESCALATOR, 'Escalator', escalator),
  new PoiType(POI_TYPE.DOOR, 'Door', door),
  new PoiType(POI_TYPE.ENTRANCE, 'Entrance', entrance),
  new PoiType(POI_TYPE.TICKET_GATE, 'Ticket Gate', ticketGate),
  new PoiType(POI_TYPE.HAZARD, 'Hazard', hazard),
  new PoiType(POI_TYPE.DECISION, 'Point of decision', decision),
  new PoiType(POI_TYPE.LANDMARK, 'Landmark', landmark),
]

export default POI_TYPES
